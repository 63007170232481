<template>
  <div>

    <b-card no-body>
      <b-card-title class="mb-0 my-1 px-1 d-flex justify-content-between">
        <div class="d-flex flex-column justify-content-center">
          {{ portfolio ? `Portfolio ${portfolio.portfolioId}: ${portfolio.portfolioName}` : 'Loading...' }}
        </div>
        <div class="d-flex">
          <b-button
            variant="warning"
            class="mr-1"
            :disabled="loading"
            @click.prevent="showEditModal = true"
          >
            Edit Portfolio Name
          </b-button>
          <b-button
            variant="danger"
            class="mr-1"
            :disabled="deleteLoading"
            @click.prevent="initDeletePortfolio"
          >
            Delete Portfolio
            <b-spinner
              v-if="deleteLoading"
              small
            />
          </b-button>
        </div>

      </b-card-title>
    </b-card>
    <b-card no-body>
      <div class="m-2 d-flex justify-content-between align-items-center">
        <h4>Products</h4>
        <div class="d-flex">
          <b-button
            variant="danger"
            :disabled="removeProductsLoading || loading || selectedProducts.length < 1"
            @click.prevent="removeProductsFromPortfolio"
          >
            Remove Selected ({{ selectedProducts.length }})
            <b-spinner
              v-if="removeProductsLoading"
              small
            />
          </b-button>
          <b-button
            v-if="maturedProducts.length > 0"
            variant="danger"
            class="ml-1"
            :disabled="deleteMaturedLoading || loading"
            @click.prevent="deleteProducts(true)"
          >
            Remove Matured Products ({{ maturedProducts.length }})
            <b-spinner
              v-if="deleteMaturedLoading"
              small
            />
          </b-button>
        </div>
      </div>
      <loading-container v-if="loading" />
      <error-display
        v-else-if="portfolioError"
        @action-clicked="loadPortfolio"
      />
      <div v-else-if="products.items.length > 0">
        <div class="font-weight-bold ml-1">
          {{ products.items.length }} results found <span v-if="products.items.length > products.perPage">| Showing results {{ `from ${showing[0]} to ${showing[1]}` }}</span>
        </div>
        <b-input-group class="input-group-merge p-1">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>
          <b-form-input
            v-model="filter"
            placeholder="Search (ID, type, product, status or date)"
            :disabled="loading"
          />
        </b-input-group>
        <b-table
          :items="filteredProducts"
          :fields="products.fields"
          :per-page="products.perPage"
          :current-page="products.currentPage"
          responsive
          hover
          @row-clicked="productSelected"
        >
          <template #cell(select)="data">
            <b-form-checkbox
              v-model="data.item.selected"
              :disabled="loading || removeProductsLoading || deleteLoading"
            />
          </template>

          <template #cell(productTypeName)="data">
            <div class="d-flex align-items-center">
              <b-avatar
                class="mr-1"
                :variant="data.item.ptIconColor"
              >
                <feather-icon
                  size="18"
                  :icon="data.item.ptIconName"
                />
              </b-avatar>
              <span>{{ data.item.productTypeName }}</span>
            </div>
          </template>

          <template #cell(added)="data">
            {{ formatDate(data.item.added) }}
          </template>

        </b-table>
      </div>
      <no-data-container
        v-else
        message="There are no products in this portfolio"
      />

      <div class="p-2 d-flex justify-content-between">
        <div class="my-2">
          <span class="pb-1">Rows Per Page</span>
          <b-form-select
            v-model="products.perPage"
            :options="products.perPageOption"
            class="pagination-select"
          />
        </div>

        <div class="my-2 d-flex flex-column justify-content-end">
          <b-pagination
            v-model="products.currentPage"
            :total-rows="filteredProducts.length"
            :per-page="products.perPage"
            align="right"
            size="sm"
            class="my-0"
          />
        </div>
      </div>
    </b-card>

    <b-modal
      v-model="showEditModal"
      centered
      title="Edit Portfolio Name"
      hide-footer
    >
      <b-form class="py-1">
        <b-form-group v-if="portfolio">
          <label for="portfolioName">Portfolio Name</label>
          <b-form-input
            id="portfolioName"
            v-model="portfolioName"
            type="text"
            :state="validationError === null ? null : false"
            placeholder="My Portfolio"
          />
          <b-form-invalid-feedback>
            {{ validationError }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-button
          variant="primary"
          class="mt-1"
          :disabled="loading"
          @click.prevent="updatePortfolioName"
        >
          UPDATE <b-spinner
            v-if="loading"
            small
          />
        </b-button>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {
  BAvatar,
  BCard,
  BCardTitle,
  BTable,
  BFormCheckbox,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BSpinner,
  BModal,
  BPagination,
  BInputGroup,
  BInputGroupPrepend,
} from 'bootstrap-vue'
import strings from '@/strings'
import LoadingContainer from '@/views/components/LoadingContainer.vue'
import ErrorDisplay from '@/views/components/ErrorDisplay.vue'
import NoDataContainer from '@/views/components/NoDataContainer.vue'
import dayjs from 'dayjs'

export default {
  components: {
    BAvatar,
    BCard,
    BCardTitle,
    BTable,
    BFormCheckbox,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormSelect,
    BSpinner,
    BModal,
    BPagination,
    BInputGroup,
    BInputGroupPrepend,
    LoadingContainer,
    ErrorDisplay,
    NoDataContainer,
  },
  props: {
    portfolioId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      removeProductsLoading: false,
      portfolioName: '',
      portfolio: {
        loss: false,
        created: '2022-02-03',
        products: 62,
        performance: 3,
        portfolioid: 1,
        avatarIcon: 'MonitorIcon',
        title: 'HNW',
        modified: '2022-03-03',
        avatarColor: 'light-primary',
        folder: 'Folder 1',
      },
      products: {
        items: [],
        fields: [
          { key: 'select', thStyle: { width: '1%' } },
          {
            key: 'productId', label: 'ID', thStyle: { width: '5%' }, sortable: true,
          },
          {
            key: 'productTypeName', label: 'Type', thStyle: { width: '15%' }, sortable: true,
          },
          {
            key: 'productName', label: 'Product', thStyle: { width: '44%' }, sortable: true,
          },
          {
            key: 'notional', label: 'Notional', thStyle: { width: '10%' }, sortable: true,
          },
          {
            key: 'status', label: 'Status', thStyle: { width: '10%' }, sortable: true,
          },
          {
            key: 'added', label: 'Date Added', thStyle: { width: '15%' }, sortable: true,
          },
        ],
        perPage: 20,
        currentPage: 1,
        perPageOption: [5, 10, 20, 50, 100],
      },
      // portfolios: [],
      validationError: null,
      deleteLoading: false,
      deleteMaturedLoading: false,
      portfolioError: null,
      showEditModal: false,
      filter: '',
    }
  },
  computed: {
    selectedProducts() {
      return this.products.items.filter(product => product.selected)
    },
    portfolios() {
      return this.$store.state.app.portfolios
    },
    maturedProducts() {
      return this.products.items.filter(product => product.status === 'Matured')
    },
    filteredProducts() {
      if (this.filter && this.filter.length > 0) {
        return this.products.items.filter(item => (item.productName.toLowerCase().search(this.filter.toLowerCase()) !== -1)
          || (item.productId.toString().search(this.filter.toLowerCase()) !== -1)
          || (item.productTypeName.toLowerCase().search(this.filter.toLowerCase()) !== -1)
          || (item.status.toLowerCase().search(this.filter.toLowerCase()) !== -1)
          || (this.formatDate(item.added).toLowerCase().search(this.filter.toLowerCase()) !== -1))
      }
      return this.products.items
    },
    showing() {
      const start = (this.products.perPage * this.products.currentPage) - this.products.perPage + 1
      const end = (this.products.perPage * this.products.currentPage) > this.products.items.length ? this.products.items.length : (this.products.perPage * this.products.currentPage)
      return [start, end]
    },
  },
  watch: {
    portfolioId() {
      this.loadPortfolio()
    },
    portfolios() {
      this.setCurrentPortfolio()
    },
  },
  activated() {
    this.loadPortfolio()
  },
  methods: {
    loadPortfolio() {
      this.loading = true
      this.portfolioError = null

      this.$http.get(`portfolio.cfc?method=getPortfolioDetails&portfolioId=${this.portfolioId}`)
        .then(response => {
          this.products.items = response.data.products
        }).catch(() => {
          this.portfolioError = true
        }).then(() => {
          this.loading = false
          this.setCurrentPortfolio()
        })
    },
    removeProductsFromPortfolio() {
      this.removeProductsLoading = true

      this.$http.post('portfolio.cfc?method=removeProductsFromPortfolio', new URLSearchParams({
        portfolioId: this.portfolioId,
        productIds: this.selectedProducts.map(product => product.productId),
      }))
        .then(() => {
          this.$bvToast.toast('Products Removed from Portfolio', {
            title: 'Success',
            variant: 'success',
            solid: true,
          })

          // Reload Portfolio Detail
          this.loadPortfolio()

          // Update store value
          this.$store.dispatch('app/updatePortfolios')
          this.$store.commit('app/SET_PORTFOLIO_UPDATED', true)
          this.$store.commit('app/ADD_UPDATED_PORTFOLIO', this.portfolioId)
        })
        .catch(() => {
          this.$bvToast.toast(strings.defaultErrorMessage, {
            title: strings.defaultErrorTitle,
            variant: 'danger',
            solid: true,
          })
        })
        .then(() => {
          this.removeProductsLoading = false
        })
    },
    updatePortfolioName() {
      // Set loading flag
      this.loading = true

      // Reset validation flag
      this.validationError = null

      // Check if the portfolio name is already used
      const nameExists = this.portfolios.find(portfolio => portfolio.portfolioName === this.portfolioName || portfolio.title === this.portfolioName)

      if (nameExists) {
        this.loading = false
        // Set input error message
        this.validationError = 'Portfolio Name Already Exists. Try Another One.'
        return
      }

      this.$http.post('portfolio.cfc?method=renamePortfolio', new URLSearchParams({
        portfolioId: this.portfolioId,
        portfolioName: this.portfolioName,
      }))
        .then(() => {
          this.$bvToast.toast('Portfolio Name Updated', {
            title: 'Success',
            variant: 'success',
            solid: true,
          })

          // Update portfolios list inside the store
          this.$store.dispatch('app/updatePortfolios')
        })
        .catch(() => {
          this.validationError = 'That didn\'t work. Please try again.'

          this.$bvToast.toast(strings.defaultErrorMessage, {
            title: strings.defaultErrorTitle,
            variant: 'danger',
            solid: true,
          })
        })
        .then(() => {
          this.loading = false
          this.showEditModal = false
        })
    },
    initDeletePortfolio() {
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to delete this portfolio?', {
          title: 'Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Delete',
          cancelTitle: 'Cancel',
          cancelVariant: 'outline-secondary',
          centered: true,
        })
        .then(value => {
          if (value) {
            this.deletePortfolio()
          }
        })
    },
    deletePortfolio() {
      this.deleteLoading = true

      this.$http.post('portfolio.cfc?method=deletePortfolio', new URLSearchParams({
        portfolioId: this.portfolioId,
      }))
        .then(() => {
          this.$bvToast.toast('Portfolio Deleted', {
            title: 'Success',
            variant: 'success',
            solid: true,
          })

          // Redirect back to portfolios page
          this.$router.push({ name: 'portfolios' })

          // Update portfolios list inside the store
          this.$store.dispatch('app/updatePortfolios')
          this.$store.commit('app/SET_PORTFOLIO_UPDATED', true)
        })
        .catch(() => {
          this.$bvToast.toast(strings.defaultErrorMessage, {
            title: strings.defaultErrorTitle,
            variant: 'danger',
            solid: true,
          })
        })
    },
    setCurrentPortfolio() {
      // Set current portfolio
      this.portfolio = this.portfolios.find(p => p.portfolioId === parseInt(this.portfolioId, 10))
      if (this.portfolio) {
        this.portfolioName = this.portfolio.portfolioName
      }
    },
    deleteProducts(matured = false) {
      this.deleteMaturedLoading = true

      const products = matured ? this.maturedProducts : this.selectedProducts

      this.$http.post('portfolio.cfc?method=removeProductsFromPortfolio', new URLSearchParams({
        portfolioId: this.portfolioId,
        productIds: products.map(product => product.productId),
      }))
        .then(() => {
          this.$bvToast.toast('Products Removed from Portfolio', {
            title: 'Success',
            variant: 'success',
            solid: true,
          })

          // Reload Portfolio Detail
          this.loadPortfolio()

          // Update store value
          this.$store.dispatch('app/updatePortfolios')
          this.$store.commit('app/SET_PORTFOLIO_UPDATED', true)
          this.$store.commit('app/ADD_UPDATED_PORTFOLIO', this.portfolioId)
        })
        .catch(() => {
          this.$bvToast.toast(strings.defaultErrorMessage, {
            title: strings.defaultErrorTitle,
            variant: 'danger',
            solid: true,
          })
        })
        .then(() => {
          this.deleteMaturedLoading = false
        })
    },
    productSelected(product) {
      // Find selected product from products array
      const itemIndex = this.products.items.findIndex(i => i.productId === product.productId)

      // Set the selected property
      this.$set(this.products.items[itemIndex], 'selected', !this.products.items[itemIndex].selected)
    },
    formatDate(date) {
      return dayjs(date).format('YYYY-MM-DD')
    },
  },
}
</script>
